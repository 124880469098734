<template>
    <div>
        <h1>{{ msg }}</h1>
    </div>
</template>

<script>
    export default {
        name: '404',
        data() {
            return {
                msg: `The page you're looking for can't be found.`
            };
        },
        created() {},
        mounted() {},
        computed: {},
        methods: {}
    };
</script>
<style scoped>
    h1 {
        margin: 100px 30px 0;
        text-align: center;
        font-size: 26px;
        line-height: 45px;
    }
</style>
